<template>
    <div class="setting_form clear notification_list_block">

        <div class="billing_box">
            <div class="heading">
                <h3>Notifications</h3>
            </div>

            <table class="table campaign_box list-content">
                <thead class="list-content-head">
                <tr>

                    <th class="">Event</th>
                    <th class="" style="text-align: right;width: 200px;">Time</th>
                </tr>
                </thead>
                <tbody class="list-content-body">
                <tr v-for="(notification, index) in getNotificationsList">
                    <td>
                        <div class="profile_box_x clear">
                           <!-- <div class="d_icon"
                                 style="background: url('https://images.unsplash.com/photo-1500382017468-9049fed747ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80');">
                            </div>-->
                            <div class="d_content">
                                <p class="content_heading" v-html="notification.message"></p>
                            </div>
                        </div>
                    </td>
                    <td style="text-align: right;">{{notification.created_at | relative}}</td>
                </tr>
                </tbody>

            </table>

        </div>
    </div>
</template>

state: {
<script>
import { mapActions, mapGetters } from 'vuex'

export default ({
  components: {},
  data () {
    return {}
  },
  created () {

  },
  computed: {
    ...mapGetters(['getNotificationsList'])
  }

})
</script>
